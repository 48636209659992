
import { dateFormatMixin } from '../../mixins'

export default {
  mixins: [dateFormatMixin],
  props: {
    publication: {
      type: Object,
      default: null,
    },
  },
  computed: {
    cardProps () {
      return {
        contentType: this.contentType,
        image: this.image,
        imageSize: this.imageSize,
        minHeight: this.minHeight,
        subtitle: this.subtitle,
        title: this.title,
        titleSize: this.titleSize,
        to: this.to,
      }
    },
    episodeDateTitle () {
      return this.publishDate && `Aflevering van ${this.dateFormat.past(this.publishDate)}`
    },
    id () {
      return this.publication && (this.publication.id || this.publication.mid)
    },
    image () {
      return this.publication && (this.publication.hero || this.publication.imageUrl)
    },
    imageSize () {
      return this.$attrs['image-size'] || 'xs:374x234 sm:608x380 md:565x353 lg:400x250'
    },
    isImageFull () {
      return this.$attrs['image-full'] || this.$attrs['image-full'] === ''
    },
    isSmall () {
      return this.$attrs.small || this.$attrs.small === ''
    },
    minHeight () {
      return this.$attrs['min-height'] || (!this.isSmall ? '380px' : this.isImageFull ? '175px' : '100px')
    },
    publishDate () {
      return this.publication && (this.publication.publishOn || this.publication.sortDate)
    },
    subtitle () {
      return this.episodeDateTitle
    },
    title () {
      return this.publication && this.publication.title
    },
    titleSize () {
      return this.isSmall ? 'sm' : 'md'
    },
    to () {
      return `${this.$config.baseUrlNpoStart}/${this.id}`
    },
  },
}
